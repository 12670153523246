import React, { Component } from "react";

class About extends Component {
  render() {
    let title = "Our Why",
      description =
        "Lillie Parker was the matriarch of our family and throughout her life always looked out for others. She would donate her money, supplies, and most importantly her time to anyone in need. As the daughter of Mrs. Parker, I opened this Non-Profit to continue her legacy in giving back.";
    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-5 col-md-12">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/about/about-10.jpg"
                    alt="About Images"
                  />
                </div>
              </div>

              <div className="col-lg-7 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">{title}</h2>
                    <p className="description">{description}</p>
                  </div>
                  <div className="row mt--30">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div>
                        <i>
                          "God knew you before you were even born, and there has
                          not been one moment <br />when he has not loved you."
                        </i>

                        <h3>
                          <i>~ Pastor Lillie M. Parker's</i>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default About;
