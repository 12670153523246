import React, { Component } from "react";
import { GoPackage, GoBriefcase, GoGift } from "react-icons/go";
import { SiJusteat } from "react-icons/si";



const ServiceList = [
  {
    icon: <GoPackage />,
    title: "Supply Drives",
    description:
      "Rather it is helping kids with school supplies or people dealing with homelessness, these supply kits are set up to help.",
  },
  {
    icon: <SiJusteat />,
    title: "Food Drives",
    description:
      "Let's do our part to help end world hunger.",
  },
  {
    icon: <GoBriefcase />,
    title: "Skill Development",
    description:
      "Our world is changing ever so quickly. We can do our part and making sure others are not left behind.",
  },
  {
    icon: <GoGift />,
    title: "Scholarships and Grants",
    description:
      "Apply for our scholarship and grant programs.",
  },
];

class ServiceTwo extends Component {
  render() {
    let title = "Our Causes",
      description =
        "100% of your donations will go to one of the following causes";
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-4 col-12">
            <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
              <h2 className="title">{title}</h2>
              <p>{description}</p>
              <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/logo/make-change.jpg"
                    alt="About Images"
                  />
                </div>
            </div>
          </div>
          <div className="col-lg-8 col-12 mt_md--50">
            <div className="row service-one-wrapper">
              {ServiceList.map((val, i) => (
                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                  <a href="/service-details">
                    <div className="service service__style--2">
                      <div className="icon">{val.icon}</div>
                      <div className="content">
                        <h3 className="title">{val.title}</h3>
                        <p>{val.description}</p>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceTwo;
