const BlogContent = [
    {
        images: '01',
        title: ' Back to school supply drive',
        category: 'Supply Drives'
    },
    {
        images: '02',
        title: ' Blessing bags for the homeless',
        category: 'Supply Drives'
    },

    {
        images: '03',
        title: 'Thanksgiving canned food drive',
        category: 'Food Drives'
    },
    {
        images: '04',
        title: ' Resume builder',
        category: 'Skill Development'
    }
]

export default BlogContent;